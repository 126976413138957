import { Router } from '@reach/router'
import React from 'react'

import { Shortener } from '../../components/studies/Shortener'

const IndexPage = () => {
  return (
    <Router basepath="/:lang/s">
      <Shortener default />
    </Router>
  )
}

export default IndexPage
