import './Shortener.less'

import { RouteComponentProps } from '@reach/router'
import { Spin } from 'antd'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useEffect, useState } from 'react'

import SurveyUnavailable from '../../../assets/images/survey-unavailable.svg'
import { useScopedIntl } from '../../../hooks'
import { AccountType, fetchExpandedUrl } from '../../../requests'
import { routes } from '../../../routes'
import { addHttpPrefix } from '../../../utils'

const ExpiredPage: React.FC = () => {
  const surveyIntl = useScopedIntl('survey.expired')
  return (
    <div className="econsent-survey-expired">
      <SurveyUnavailable />
      <p>{surveyIntl('label')}</p>
      <p>{surveyIntl('content.1')}</p>
      <p>{surveyIntl('content.2')}</p>
    </div>
  )
}

export const Shortener: React.FC<RouteComponentProps> = ({ location }) => {
  const [isExpired, setIsExpired] = useState(false)
  useEffect(
    () =>
      fetchExpandedUrl(
        { code: location.search.substring(1) },
        {
          onSuccess: url => {
            window.location.href = addHttpPrefix(url)
          },
          onExpiredError: () => {
            setIsExpired(true)
          },
          onRequestError: () => {
            navigate(routes.notFound(AccountType.Subject))
          }
        }
      ),
    []
  )

  return isExpired ? (
    <ExpiredPage />
  ) : (
    <div className="shortener__loading">
      <Spin />
    </div>
  )
}
